.dash_button_disabled{
    color: #BBBED7;
    background-color: rgba(187, 190, 215, 0.2);
    border-radius: 4px;
    
}

.dash_button_active{
    color:#4EAF67;
    background: rgba(78, 175, 103, 0.1);
    cursor:default;
}

.dash_button_buy{
  color:var(--primary);
  background: rgba(234, 45, 45, 0.1);
  cursor:default;
}

.dash_red_opacity{
  color: rgba(234, 45, 45, 0.1);
}

.dash_button_expired{
    color:#EEBB07;
    background: rgba(238, 187, 7, 0.1);
    cursor:default;
}

.dash_button_neutral{
    color:rgba(187, 190, 215, 1);
    background: rgba(187, 190, 215, 0.1);
    cursor:default;
}

.dash_button_red{
    color: white;
    background: var(--primary);
    cursor:default;
}

.dash_button_pointer{
  cursor:pointer
}

.dash_action_button{
  /* background-color: rgba(132, 135, 138, 0.4); */
  color:rgba(132, 135, 138, 0.4);
}

.dash__color{
    color:var(--dashcolor);
}

.dash__blue__color{
  color:#004E92;

}

.dash_color_disabled{
    color: #BBBED7;
}


.dashboard__background{
    background-color: #F8F9FC;
}

.dashboard__height{
    min-height:150vh;
}

.dash_border-b{
    border-bottom:1px solid #E7E7E7;
}

.dash_border-t{
  border-top:1px solid #E7E7E7;
}

.dash__subtitle{
    color:var(--black);
    font-size: 1rem;
    opacity: 0.5;
}

.dash__text{
    color:var(--black);
    font-size:1rem;
}

.dash__border{
  border: 0.5px solid #BBBED7;
  border-radius: 10px;
}

.dash__border__div{
  border: 1px solid #E2E2EA;
}



.dash_border_radius{
  border-radius: 4px;
}

.dash_disable_border{
  border: 1px solid var(--disableBordercolor);
  border-radius: 4px;
}

.dash_active_border{
  border: 1px solid var(--activeBordercolor);
  border-radius: 4px;
}

.dash_border_white{
  border: 1px solid white;
}

.dash_cell_bg{
  background-color: var(--cellBg);
}

.dash_cell_color{
  color: var(--cellColor);
}


.dash-p-4{
  padding: 1rem !important
}

.dash__otp{
  width:4rem !important;
  height:4rem !important;
}


@media only screen and (max-width:37.5em) {
  .dash__otp {
    width:2.5rem !important;
    height:2.5rem !important;
  }
}

.dash__border__otp{
  border: 1px solid #75759E;
}


.dash__card__shadow{
  box-shadow: 0px 0px 10px 2px rgba(0, 106, 169, 0.07);
}

/* TABLE */

table {
    /* border-collapse: collapse; */
    width: 100%;
    border-radius: 20px 20px 0px 0px !important;
    padding: 1rem;
  }
  
  table td,
  table th {
    /* border: 1px solid #ddd; */
    padding: 1.2rem;
  }
  
  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  table tr:hover {
    background-color: #F9FAFB;
  }

  table th:first-child(){
    border-radius: 20px 0px 0px 0px !important;
  }

  table thead th:nth-last-child(){
    border-radius: 0px 20px 0px 0px !important;
  }

  table th{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    /* border-radius: 20px 0px 0px 0px !important; */
    color:#75759E;
    font-weight: normal
    ;
    /* background-color: #4caf50; */
    /* color: white; */
  }

  table td{
    color:var(--tablecolor)
  }
  table thead{
    background: #F0F2F4 !important;
    
  }

.select__border{
   border: 1px solid #DFE3E8;
}