.type__checkbox {
    border: 1px solid var(--primary);
    outline: var(--primary);
}
.type__checkbox:focus {
    background-color: var(--primary);
}

.form__submit {
    background-color: var(--primary);
    border-radius: 4px;
    cursor: pointer;
}

.form__submit__disabled {
    background-color: rgb(75 85 99);
    cursor: not-allowed;
}

.button__padding{
    padding: 1rem 2rem;
}