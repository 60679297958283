@tailwind base;
@tailwind components;
@tailwind utilities;

@import url( 'https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400&display=swap');
:root {
    --primary: #F32D2D;
    --black: #282828;
    --dashcolor: #75759E;
    --tablecolor: #4B545A;
    --activeBordercolor: #004E92;
    --disableBordercolor: #DFE3E8;
    --cellBg:#F8F8F8;
    --cellColor:#8692A7;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth;

    /*1rem = 10px; 10px/16px = 62.5% */
    /* font-size: 62.5%; */
}

body {
    font-family: 'Overpass', 'san-serif';
    box-sizing: border-box;
    color:var(--black);

    overflow: hidden;
}

::-webkit-scrollbar {
    width: 12px;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

::selection {
    background-color: var(--primary);
    color: white;
}

/*  Typography CSS */

.text__link {
    font-size: 1.6rem;
    color: var(--black);
}

.text__link .active {
    color: var(--primary);

}

.text__heading {
    font-size: clamp(1.6rem, 2.2rem, 2.5rem);
    font-weight: bold;
}
@media only screen and (max-width:37.5em) {
    .text__heading {
        font-size: 1.6rem;
    }
}

.text__paragraph {
    font-size: clamp(1rem, 1.2rem, 1.4rem);
}

.text__subtitle {
    font-size: clamp(1.1rem, 1.37rem, 1.4rem);
}


.slick-dots li.slick-active button:before {
    background: #FFFFFF;
    color: transparent !important;
    border: 3px solid #F32D2D;
    border-radius: 50%;
    width:15px;
    height:15px;
}

.primary__color{
    color:var(--primary)
}


.flex__center{
    display: flex;
    justify-content: center;
    align-items:center;
}

.flex__start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}